import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    var swiper = new Swiper('.gallery-modal-slider', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: '.banner-button-next',
            prevEl: '.banner-button-prev',
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });
};
